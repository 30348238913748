import { request, gql } from 'graphql-request'
import * as React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { QueryClientProvider, useQuery, useQueryClient } from '@tanstack/react-query'

import { url } from '../graphql'

const peopleQuery = gql`
query {
    people {
      id
      name
      messages
    }
}
`

const addMessageMutation = gql`
mutation AddMessageForHuman($id: String!, $message: String!) {
    addMessage(userId: $id, text: $message)
}
`

const List = ({ addMessage }) => {
    const { isPending, error, data } = useQuery({
        queryKey: ['people'],
        queryFn: async () => request(url, peopleQuery),
    })

    if (isPending) return 'Loading...'

    if (error) return 'An error has occurred: ' + error.message

    const { people } = data as { people: {id: string, name: string, messages: string[]}[] }
    return people.map(({ id, name, messages }) => (
        <Accordion key={id}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${name}-content`}
                id={`${name}-header`}
            >
                <Typography>{name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {messages.map(msg => <Typography>{msg}</Typography>)}
                {messages.length < 5 && <Button variant="contained" onClick={() => addMessage(id, name)}> Add message</Button>}
            </AccordionDetails>
        </Accordion>
    ))
}

export default () => {
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const [id, setId] = React.useState('')
    const [name, setName] = React.useState('')
    const addMessageFor = React.useCallback((id: string, name: string) => {
        setId(id)
        setName(name)
        handleOpen()
    }, [handleOpen, setId, setName])

    const queryClient = useQueryClient()

    return (
        <Container>
            <QueryClientProvider client={queryClient}>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        component: 'form',
                        onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
                            event.preventDefault()
                            const data = Object.fromEntries<any>(new FormData(event.currentTarget))
                            await request(url, addMessageMutation, {...data, id})
                            queryClient.invalidateQueries(['people'])
                            handleClose()
                        },
                    }}
                >
                    <DialogTitle>Add message</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Enter message for {name}
                        </DialogContentText>
                        <TextField
                            autoFocus
                            required
                            margin="dense"
                            id="message"
                            name="message"
                            label="Message"
                            type="message"
                            fullWidth
                            variant="standard"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">Save</Button>
                    </DialogActions>
                </Dialog>
                <List addMessage={addMessageFor} />
                <Button variant="contained" disabled>Add person</Button>
            </QueryClientProvider>
        </Container>
    )
}
