import * as React from 'react'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import GraphQLPage from './GraphqlPage'
import ListPage from './ListPage'

const router = createBrowserRouter([
    {
        path: '/',
        element: <ListPage />
    },
    {
        path: '/graphql',
        element: <GraphQLPage />
    },
])

export const Router = () => <RouterProvider router={router} />
