import * as React from 'react'
import Container from '@mui/material/Container'
import { createGraphiQLFetcher } from '@graphiql/toolkit'
import { GraphiQL } from 'graphiql'
import 'graphiql/graphiql.css'

import { url } from '../graphql'

const fetcher = createGraphiQLFetcher({
    url,
})

export default () => {
    return (
        <Container>
            <GraphiQL fetcher={fetcher} />
        </Container>
    )
}
